/**
 * Adds the given budget delta to campaigns
 * sequentially in place.
 * 
 * @param {Array}   campaigns     array of campaigns whose budget
 *                                should be updated
 * @param {Number}  budgetDelta   the budget to be added
 * @returns {Array}               the updated campaigns
 */

function addBudget (campaigns, budgetDelta) {
  var availableBudget = budgetDelta;

  for (let i = 0; i < campaigns.length; i++) {
    const campaign = campaigns[i];
    const capacity = campaign.availableDailyCapacity;


    if (capacity > 0 && availableBudget > 0) {

      if (availableBudget > capacity) {
        availableBudget -= capacity;
        campaign.proposedBudget = campaign.budget + capacity;
      } else {
        campaign.proposedBudget = campaign.budget + availableBudget;
        availableBudget = 0;
      }
    } else {
      campaign.proposedBudget = campaign.budget;
    }
  }

  return campaigns;
}

/**
 * Removes the given budget delta from campaigns
 * sequentially in place.
 * 
 * @param {Array}   campaigns     array of campaigns whose budget
 *                                should be updated
 * @param {Number}  budgetDelta   the budget to be removed
 * @returns {Array}               the updated campaigns
 */
function removeBudget (campaigns, budgetDelta) {
  var budgetToRemove = budgetDelta;

  for (let i = 0; i < campaigns.length; i++) {
    const campaign = campaigns[i];
  
    if (campaign.budget > 0 && budgetToRemove > 0) {

      const campaignBudgetDelta = Math.min(campaign.budget, budgetToRemove);

      campaign.proposedBudget = campaign.budget - campaignBudgetDelta;
      budgetToRemove -= campaignBudgetDelta;
    } else {
      campaign.proposedBudget = campaign.budget;
    }
  }

  return campaigns;
}

/**
 * Creates a deep clone of the given campaigns and
 * sorts them by a metric (and order).
 * 
 * @param {Array}   campaigns     the campaigns to be sorted
 * @param {String}  metric        the budget to be removed
 * @param {Boolean} ascending     default sort in ascending order
 * @returns {Array}               the sorted campaigns
 */
function sortCampaigns (campaigns, metric, ascending) {
  const sortedCampaigns = structuredClone(campaigns);

  sortedCampaigns.sort((a, b) => {

    if (metric === 'costPerConversion') {
      if (a.conversions === 0) return 1;
      if (b.conversions === 0) return -1;
    }

    // Sort by the specified metric
    if (a[metric] > b[metric]) {
      return 1;
    } else if (a[metric] < b[metric]) {
      return -1;
    }

    return 0;
  });

  if (!ascending) {
    sortedCampaigns.reverse();
  
    if (metric === 'costPerConversion') {
      // Move campaigns with 0 cost/conv to the end
      sortedCampaigns.sort((a, b) => {
        if (a.conversions === 0 && b.conversions !== 0) return 1;
        if (b.conversions === 0 && a.conversions !== 0) return -1;
        return 0;
      });
    }
  }

  return sortedCampaigns;
}

export {
  addBudget,
  removeBudget,
  sortCampaigns
}