/**
* This file defines a skeleton structure for the pacing metrics of budget groups.
* The skeleton provides a default structure for the pacing metrics data, which can be used
* when the actual data is not available yet. This can be useful for displaying a consistent
* structure in the UI while the actual data is being fetched.
*
* The `budgetGroupsPacingMetricsSkeleton` object has the following properties:
*
* - `timeframeEndDate`: The end date of the timeframe for the pacing metrics. This is initially set to `null`.
* - `generatedOn`: The date when the pacing metrics were generated. This is initially set to `null`.
* - `cumulativeCost`: The cumulative cost of the budget group up to the `timeframeEndDate`. This is initially set to `null`.
* - `forecastTotalSpend`: The forecasted total spend of the budget group for the entire budget period. This is initially set to `null`.
* - `percentageForecastTotalSpend`: The forecasted total spend as a percentage of the total budget. This is initially set to `null`.
* - `budgetStatus`: The status of the budget group. This is initially set to `"unknown"`.
*
* The `budgetGroupsPacingMetricsSkeleton` object is exported from this file and can be imported in other parts of the application.
*/

const budgetGroupsPacingMetricsSkeleton = {
    timeframeEndDate: null,
    generatedOn: null,
    cumulativeCost: null,
    forecastTotalSpend: null,
    percentageForecastTotalSpend: null,
    budgetStatus: "unknown"
}

export {
    budgetGroupsPacingMetricsSkeleton
}