import { useState } from "react";
import { useAuth } from "../api/auth.context";
import { useNavigate } from "react-router-dom";

import {
  Typography,
  Container,
  TextField,
  Button,
  Stack,
  Avatar,
  Snackbar,
  Alert
} from "@mui/material";

import ReCAPTCHA from "react-google-recaptcha";
import LockIcon from "@mui/icons-material/LockOutlined";

import { useApiClient } from "../api/client";


export default function Login () {
  const navigate = useNavigate();
  const apiClient = useApiClient();

  const { updateUser } = useAuth();

  const [email, setEmail] = useState({
    email: "",
    error: false,
    helperText: ""
  });

  const [password, setPassword] = useState({
    password: "",
    error: false,
    helperText: ""
  });

  const [snackbarStatus, setSnackbarStatus] = useState({
    open: false,
    message: ""
  }); 

  const [recaptchaToken, setRecaptchaToken] = useState(null)

  const login = function () {
    if (password.error || email.error) {
      setSnackbarStatus({
        open: true,
        message: "Invalid credentials: check the form and retry"
      })

      return
    }

    if (recaptchaToken == null) {
      console.log("ReCaptcha was not completed");

      setSnackbarStatus({
        open: true,
        message: "Complete the ReCaptcha first!"
      });

      return;
    }

    apiClient.login(email.email, password.password, recaptchaToken).then((user) => {
      console.log(`User ${email.email} logged in`);

      updateUser(user);      
      navigate("/");
    }).catch((error) => {
      setSnackbarStatus({
        open: true,
        message: "Invalid credentials. Please retry"
      });
    })
  }

  return (
    <Container
      maxWidth="xs"
      sx={{
        marginTop: 8
      }}
    >
      <Stack
        sx={{
          alignItems: "center",
          marginBottom: 3
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockIcon />
        </Avatar>

        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
      </Stack>
      <Stack
        spacing={2}
        sx={{
          alignItems: "center"
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          label="Email"
          type="email"
          required
          value={email.email}
          error={email.error}
          helperText={email.helperText}
          onChange={(event) => {
            const validEmail = event.target.value.length > 0;

            setEmail({
              email: event.target.value,
              error: !validEmail,
              helperText: !validEmail ? "The email cannot be empty" : ""
            });
          }}
        />

        <TextField
          fullWidth
          variant="outlined"
          label="Password"
          type="password"
          aria-required
          value={password.password}
          error={password.error}
          helperText={password.helperText}
          onChange={(event) => {
            const validPassword = event.target.value.length > 0;

            setPassword({
              password: event.target.value,
              error: !validPassword,
              helperText: !validPassword ? "The password cannot be empty" : ""
            });
          }}
        />

        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
          onChange={(value) => {
            setRecaptchaToken(value);
          }}
        />

        <Button fullWidth variant="contained" onClick={login}>
          Sign In
        </Button>
      </Stack>
      <Snackbar
        open={snackbarStatus.open}
        autoHideDuration={3000}
        onClose={() => {
          setSnackbarStatus({
            open: false,
            message: ""
          });
        }}
      >
        <Alert
          severity="error"
          sx={{ width: "100%" }}
          onClose={() => {
            setSnackbarStatus({
              open: false,
              message: ""
            });
          }}
        >
          {snackbarStatus.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}
